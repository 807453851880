import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Suspense, lazy, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { AuthRoute } from "../App";
import "../App.css";
import {
  getUserRoleForActiveGym,
  isAppInitialized,
} from "../redux/selectors/app";
import Routines from "../tabs/workouts/Routines";
import AddExercises from "../tabs/workouts/create/AddExercises";
import AddWorkoutDay from "../tabs/workouts/create/AddWorkoutDay";
import CreateWorkout from "../tabs/workouts/create/CreateWorkout";
import { ExerciseStats } from "../tabs/workouts/log-workouts/ExerciseStats";
import LogWorkouts from "../tabs/workouts/log-workouts/LogWorkouts";
import Performance from "../tabs/workouts/log-workouts/Performance";
import WorkoutHistory from "../tabs/workouts/log-workouts/WorkoutHistory";
import DayExercises from "../tabs/workouts/routine-days/DayExercises";
import ChooseWorkoutDay from "../tabs/workouts/start-workout/ChooseWorkoutDay";

const ActivateAccount = lazy(() => import("../tabs/auth/ActivateAccount"));
const ForgotPassword = lazy(() => import("../tabs/auth/ForgotPassword"));
const Login = lazy(() => import("../tabs/auth/Login"));
const Register = lazy(() => import("../tabs/auth/Register"));
const ResetPassword = lazy(() => import("../tabs/auth/ResetPassword"));
const Dashboard = lazy(() => import("../tabs/dashboard/Dashboard"));
const AddExpenses = lazy(() => import("../tabs/expenses/create/AddExpenses"));
const ExpensesList = lazy(() => import("../tabs/expenses/list/ExpensesList"));
const GymProfile = lazy(() => import("../tabs/gym/GymProfile"));
const Amenities = lazy(() => import("../tabs/gym/amenities/Amenities"));
const Gallery = lazy(() => import("../tabs/gym/gallery/Gallery"));
const GymInfo = lazy(() => import("../tabs/gym/info/GymInfo"));
const AddMember = lazy(() => import("../tabs/members/create/AddMember"));
const MembersList = lazy(() => import("../tabs/members/list/MembersList"));
const AddPayments = lazy(() => import("../tabs/payments/create/AddPayments"));
const PaymentList = lazy(() => import("../tabs/payments/list/PaymentList"));
const AddPlans = lazy(() => import("../tabs/plans/create/AddPlans"));
const PlansList = lazy(() => import("../tabs/plans/list/PlansList"));
const Settings = lazy(() => import("../tabs/settings/Settings"));
const AddStaff = lazy(() => import("../tabs/staff/create/AddStaff"));
const StaffList = lazy(() => import("../tabs/staff/list/StaffList"));
const SubscriptionAdd = lazy(() =>
  import("../tabs/subscriptions/SubscriptionAdd")
);
const SubscriptionsList = lazy(() =>
  import("../tabs/subscriptions/SubscriptionsList")
);
const GymTimings = lazy(() => import("../tabs/timings/GymTimings"));

const AdminRoutes = () => {
  const location = useLocation();
  const userRole = useSelector(getUserRoleForActiveGym);
  const isInitialized = useSelector(isAppInitialized);

  const initialRoute = useMemo(() => {
    if (userRole === 2) {
      return "/dashboard";
    }
    return "/members";
  }, [userRole]);

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/activate/:token" element={<ActivateAccount />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {/* <Route path="/send-confirmation" element={<SendRegisterEmail />} /> */}

        <Route element={<AuthRoute />}>
          <Route
            path="/"
            element={
              !isInitialized && !userRole ? (
                <div className="flex justify-center items-center h-full">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 48 }} spin />
                    }
                  />
                </div>
              ) : (
                <Navigate to={initialRoute} />
              )
            }
          >
            {/* <Route index element={initialRoute} /> */}
          </Route>

          <Route
            element={
              userRole === 2 ? <Outlet /> : <Navigate to={location.pathname} />
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/staff" element={<StaffList />} />
            <Route path="/staff/add/:staffId?" element={<AddStaff />} />
          </Route>
          <Route path="/members" element={<MembersList />} />
          <Route path="/members/add/:memberId?" element={<AddMember />} />
          <Route path="/members/routines/:memberId" element={<Routines />} />

          <Route
            path="/subscriptions/:memberId"
            element={<SubscriptionsList />}
          />
          <Route
            path="/subscriptions/:memberId/add"
            element={<SubscriptionAdd />}
          />

          <Route path="/payments" element={<PaymentList />} />
          <Route path="/payments/add" element={<AddPayments />} />

          <Route path="/plans" element={<PlansList />} />
          <Route path="/plans/add" element={<AddPlans />} />
          <Route path="/plans/add/:planId" element={<AddPlans />} />

          <Route path="/expenses" element={<ExpensesList />} />
          <Route path="/expenses/add/:expenseId?" element={<AddExpenses />} />

          <Route path="/gym" element={<GymProfile />}>
            <Route index element={<Navigate to="info" />} />
            <Route path="info" element={<GymInfo />} />
            <Route path="plans" element={<PlansList />} />
            <Route path="timings" element={<GymTimings />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="amenities" element={<Amenities />} />
          </Route>

          <Route path="/settings" element={<Settings />} />

          <Route path="/workouts" element={<Routines />} />
          <Route path="/workouts/add" element={<CreateWorkout />} />
          <Route path="/workout-day/:routineId" element={<AddWorkoutDay />} />
          <Route
            path="/add-exercise/:routineId/:dayId"
            element={<AddExercises />}
          />
          <Route
            path="/start-workout/:routineId/:memberId?"
            element={<ChooseWorkoutDay />}
          />
          <Route
            path="/workout-exercise/:routineId/:dayId/:memberId?"
            element={<DayExercises />}
          />
          <Route
            path="/log-exercise/:routineId/:dayId/:exerciseId/:memberId?"
            element={<LogWorkouts />}
          >
            <Route index element={<Navigate to={"stats"} />} />
            <Route path="stats" element={<ExerciseStats />} />
            <Route path="history" element={<WorkoutHistory />} />
            <Route path="performance" element={<Performance />} />
          </Route>
        </Route>
        {/* <Route element={<FullScreenLayout />}>
      <Route path="/onboarding" element={<div>on boarding</div>} />
    </Route> */}
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </Suspense>
  );
};

export default AdminRoutes;
